@use "styles" as *;

.background {
  &.white {
    background-color: $white;
  }
  &.pink {
    background-color: $light-pink;
  }
}

.container {
  padding: 24px 0;
  overflow: hidden;
  text-align: center;
  position: relative;

  @include sm-desktop {
    margin: 0 18.5%;
  }

  &.removeHorizontalMargin {
    @include sm-desktop {
      @include margins;
    }
  }
  &.removeVerticalPadding {
    padding: 0;
  }
}

.description {
  @include text-s-regular;
}
